import { Col, Row, Card, Divider, Radio } from "antd";
import {
  CaretRightOutlined,
  CaretDownOutlined,
  CaretLeftOutlined,
} from "@ant-design/icons";
import { ReactElement, useState } from "react";
import { FORMATS, LOCALIZATION_LANGUAGES, REGEXES } from "src/constants";
import i18n from "src/i18n";
import {
  QBackLink,
  QButton,
  QDatePicker,
  QInput,
  qnotification,
  QRadio,
} from "quantum_components";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { disabledPastDates } from "src/helpers/datesHelper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getBriefSettings } from "src/modules/brief/brief.selectors";
import { fetchBriefSettings } from "src/modules/brief/brief.actions";
import IUploadFile from "src/interfaces/IUploadFile";
import lodash, { fill, template } from "lodash";
import V3TargetingComponent from "../components/V3Targeting.component";
import V3ServicesComponent from "../components/V3Services.component";
import V3NativeTemplatesComponent from "../components/V3NativeTemplates.component";
import {
  fetchEditBriefAsync,
  updateBriefAsync,
  upsertV3Campaign,
} from "../briefv3.actions";
import V3UploadComponent from "../components/V3Upload.component";
import V3CartComponent from "../components/V3Cart.component";
import { getCurrentUser } from "src/modules/core/core.selectors";
import moment from "moment";
import {
  briefPaymentStatuses,
  briefStatuses,
  BRIEF_PAYMENT_STATUS,
  BRIEF_PRINT_ID,
} from "src/constants/brief";
import React from "react";
import BriefQuotationV3 from "src/modules/brief/containers/briefPayment/Brief.V3.Quotation";
import V3PaymentMethodsComponent from "../components/V3PaymentMethods.component";
import SpinnerCenter from "src/components/Spinner/spinner";
import V3UploadBannerMediaComponent from "../components/V3UploadBannerMedia.component";
import DialogModal from "src/components/DialogModal/DialogModal";
import V3LogicComponent from "../components/V3Logic.component";
import { calculateBudgetFeedback } from "../briefv3.helper";
import V3ProductComponent from "../components/V3Product.component";
import V3ServicesV2Component from "../components/V3ServicesV2.component";

export default function BriefEditV3Container(): ReactElement {
  const { t } = useTranslation([
    "translationV2",
    "translationABrief",
    "translation",
    "translationProduct",
  ]);

  const history = useHistory();
  const dispatch = useDispatch();
  const briefSettings = useSelector(getBriefSettings);
  const currentUser = useSelector(getCurrentUser);
  const [modalOpen, setModalOpen] = useState(false);
  const [thumbnails, setThumbnails] = useState<IUploadFile[]>([]);
  const [selectedTargeting, setSelectedTargeting] = useState({});
  const [selectedTargetingPriority, setSelectedTargetingPriority] = useState(
    "CASUAL"
  );
  const [selectedTargetingLogic, setSelectedTargetingLogic] = useState({});

  // const [showUploadAr, setShowUploadAr] = useState(true);
  // const [showUploadEn, setShowUploadEn] = useState(false);
  // const [showAdvanced, setShowAdvanced] = useState(false);


  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedPublishers, setSelectedPublishers] = useState([]);
  const [selectedNativeTemplate, setSelectedNativeTemplate] = useState<any>({});
  const [cart, setCart] = useState<any>({
    servicesTotal: 0,
    managementFee: 0,
    discount: 0,
    subTotal: 0,
    totalTax: 0,
    cartTotal: 0,
  });
  const [targetingType, setTargetingType] = useState<any>([]);
  const [briefResponse, setBriefResponse] = useState<any>({});
  const requiredFields = ["campaignName", "services"];
  const [briefData, setBriefData] = useState({
    campaignName: null,
    startDate: null,
    thumbnail: null,
    targeting: null,
    services: null,
    template: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const enableCampaignButton =
    lodash.get(briefResponse, "rawResponse.status") === "APPROVED" &&
    (["PAID", "PO_ISSUED"].includes(
      lodash.get(briefResponse, "rawResponse.payment.status")
    ) ||
      lodash.get(briefResponse, "rawResponse.payment.isPaymentOverridden"));
  const { id: briefId } = useParams<any>();
  const canEdit = ["DRAFT", "PENDING", "REJECTED"].includes(
    briefResponse?.rawResponse?.status
  );

  const getBriefDetails = async () => {
    if (!briefId) {
      history.push("/briefs");
      return;
    }
    setIsLoading(true);
    let data = await fetchEditBriefAsync(briefId);
    setBriefResponse(data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (lodash.isEmpty(briefResponse) || lodash.isEmpty(briefSettings)) return;

    if (briefResponse?.rawResponse?.selectedServices) {
      const allPublishers = briefSettings?.publishers;
      const selected: any = [];
      briefResponse?.rawResponse?.selectedServices.map((ss: any) => {
        const existingPublisher = lodash.find(
          allPublishers,
          (p: any) => p.id === ss.publisherId
        );
        if (existingPublisher) {
          const publisherObj = {
            id: ss.publisherId,
            companyName: existingPublisher.companyName,
          };
          selected.push(publisherObj);
        }
      });

      setSelectedPublishers(selected);
    }

    setBriefData({
      ...briefData,
      campaignName: briefResponse?.rawResponse?.campaignName,
      // @ts-ignore
      startDate: lodash.get(
        briefResponse,
        "rawResponse.cart.additional.services[0].startDate"
      )
        ? moment(
          briefResponse?.rawResponse?.cart?.additional?.services[0]?.startDate
        )
        : null,
      thumbnail: null,
    });

    if (briefResponse?.rawResponse?.allImages) {
      const productImages = briefResponse?.rawResponse?.allImages
        ?.filter((i: any) => i.entity == "briefs_product_images")
        .map((o: any) => o.src);
      setThumbnails(productImages);
    }

    if (briefResponse?.rawResponse?.cart?.additional?.services[0]?.targeting) {
      setSelectedTargeting(
        briefResponse?.rawResponse?.cart?.additional?.services[0]?.targeting
      );
    }

    if (
      briefResponse?.rawResponse?.cart?.additional?.services[0]
        ?.targetingPriority
    ) {
      setSelectedTargetingPriority(
        briefResponse?.rawResponse?.cart?.additional?.services[0]
          ?.targetingPriority ?? "CASUAL"
      );
    }

    if (
      briefResponse?.rawResponse?.cart?.additional?.services[0]?.targetingLogic
    ) {
      setSelectedTargetingLogic(
        briefResponse?.rawResponse?.cart?.additional?.services[0]
          ?.targetingLogic ?? {}
      );
    }

    if (briefResponse?.rawResponse?.cart?.additional?.services) {
      setSelectedServices(
        briefResponse?.rawResponse?.cart?.additional?.services.map((s: any) => {
          s.startDate = s.startDate ? moment(s.startDate) : null;
          s.activeNativeTemplate = s.activeNativeTemplate ?? {};
          s.activeProduct = s.activeProduct ?? {};
          s.targetingPriority = s.targetingPriority ?? "CASUAL";
          s.targetingLogic = s.targetingLogic ?? [];

          s.showUploadAr = s.showUploadAr ?? true;
          s.showUploadEn = s.showUploadEn ?? false;
          s.showAdvanced = s.showAdvanced ?? false;

          s.width = s.width ?? -1;
          s.height = s.height ?? -1;

          s.targetingType = lodash.isEmpty(s.targetingLogic)
            ? "keywords"
            : "advanced";

          return s;
        })
      );
    }

    if (briefResponse?.rawResponse?.cart?.additional?.services[0]?.templateId) {
      setSelectedNativeTemplate({
        // @ts-ignore
        ...briefSettings?.nativeadTemplates?.find(
          (o: any) =>
            o.id ===
            briefResponse?.rawResponse?.cart?.additional?.services[0]
              ?.templateId
        ),
        values:
          briefResponse?.rawResponse?.cart?.additional?.services[0]
            ?.templateVariables,
      });
    }
  }, [briefResponse, briefSettings]);

  useEffect(() => {
    dispatch(fetchBriefSettings());
    getBriefDetails();
  }, []);

  const handleFormChange = (controlName: any, value: any) => {
    setBriefData({ ...briefData, [controlName]: value });
  };

  const handleDatePicker = (date: moment.Moment, index: number) => {
    const ss = [...selectedServices];
    // @ts-ignore
    ss[index].startDate = date;

    setSelectedServices(ss);
  };

  const handleUrlChange = (value: string, index: number) => {
    const ss = [...selectedServices];
    // @ts-ignore
    ss[index].redirectUrl = value;

    setSelectedServices(ss);
  };

  const handleValueChange = (control: string, value: any, index: number) => {
    const ss = [...selectedServices];

    // @ts-ignore
    ss[index][control] = value;

    setSelectedServices(ss);
  };

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const isNotEmptyUrlValid = (url: string) => {
    try {
      if (url) {
        new URL(url);
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const generatePayload = () => {
    return {
      ...briefData,
      targeting: selectedTargeting,
      services: selectedServices,
      template: selectedNativeTemplate,
      thumbnail: thumbnails,
      payload: {
        campaignName: briefData.campaignName,
        id: parseInt(briefId),
        clientId: briefResponse?.rawResponse?.clientId ?? currentUser.id,
        selectedServices: selectedServices.map((o: any) => {
          return {
            id: o.id,
            type: o.type,
            subName: o.name,
            publisherId: o.publisherId || null,
          };
        }),
        cart: {
          services: selectedServices.map((o: any) => {
            const {
              totalQuantity,
              estimatedDaysForTotalQuantity,
              dailyQuantity,
              estimatedDaysForDailyQuantity,
              dailyDistribution,
            }: any = calculateBudgetFeedback(o);

            return {
              hasServerIntegration: o.hasServerIntegration,
              width: o.width ?? -1,
              height: o.height ?? -1,
              id: o.id,
              publisherId: o.publisherId || null,
              name: o.name,
              startDate: o.startDate,
              targetingType: o.targetingType ?? "keywords",
              targeting: o.targeting,
              targetingPriority: o.targetingPriority ?? "CASUAL",
              targetingLogic: o.targetingLogic ?? [],

              showUploadAr: o.showUploadAr ?? true,
              showUploadEn: o.showUploadEn ?? false,
              showAdvanced: o.showAdvanced ?? false,

              templateId: o?.activeNativeTemplate?.id ?? o.templateId,
              templateVariables:
                o?.activeNativeTemplate?.values ?? o.templateVariables,
              activeNativeTemplate: o?.activeNativeTemplate,

              productId: o.productId ?? -1,
              activeProduct: o.activeProduct ?? {},
              mappedTemplate: o.mappedTemplate ?? {},

              durationType: o.durationType,
              quantity: o.quantity,
              basePrice: o.basePrice,
              total: o.total,
              type: o.type,
              status: o.status === "REJECTED" ? "PENDING" : o.status,
              rejectionReason: o.rejectionReason ?? null,
              redirectUrl: o.redirectUrl,
              adTitle: o?.adTitle ?? null,
              adDescription: o?.adDescription ?? null,
              altText: briefData.campaignName ?? o.altText,
              impressionsPerDay: o?.impressionsPerDay ?? 0,
              metricData: {
                metricType: o?.metricData?.metricType ?? "",
                campaignBudget: o?.metricData?.campaignBudget ?? 0,
                dailyBudget: o?.metricData?.dailyBudget ?? 0,
                minDuration: o?.metricData?.minDuration ?? 0,

                totalQuantity: totalQuantity ?? 0,
                servedQuantity: 0,
                estimatedDaysForTotalQuantity:
                  estimatedDaysForTotalQuantity ?? 0,
                dailyQuantity: dailyQuantity ?? 0,
                estimatedDaysForDailyQuantity:
                  estimatedDaysForDailyQuantity ?? 0,
                dailyDistribution: dailyDistribution ?? [],
              },
              pricePerMetric: {
                cpm: o.pricePerMetric?.cpm ?? 0,
                cpc: o.pricePerMetric?.cpc ?? 0,
              },
              imageTargetUrl: {
                en: {
                  mobile:
                    o?.imageTargetUrl?.en?.mobile ??
                    o?.imageTargetUrl?.en?.desktop ??
                    o?.imageTargetUrl?.ar?.mobile ??
                    o?.imageTargetUrl?.ar?.desktop ??
                    null,
                  desktop:
                    o?.imageTargetUrl?.en?.desktop ??
                    o?.imageTargetUrl?.en?.mobile ??
                    o?.imageTargetUrl?.ar?.desktop ??
                    o?.imageTargetUrl?.ar?.mobile ??
                    null,
                },
                ar: {
                  mobile:
                    o?.imageTargetUrl?.ar?.mobile ??
                    o?.imageTargetUrl?.ar?.desktop ??
                    o?.imageTargetUrl?.en?.mobile ??
                    o?.imageTargetUrl?.en?.desktop ??
                    null,
                  desktop:
                    o?.imageTargetUrl?.ar?.desktop ??
                    o?.imageTargetUrl?.ar?.mobile ??
                    o?.imageTargetUrl?.en?.desktop ??
                    o?.imageTargetUrl?.en?.mobile ??
                    null,
                },
              },
            };
          }),
          ...cart,
        },
      },
    };
  };

  const validateData = () => {
    
    const bd: any = generatePayload().payload;
    const errorFields: any = [];

    const hasCampaignName = lodash.get(bd, "campaignName");
    if (!hasCampaignName)
      !errorFields.includes("Campaign Name") &&
        errorFields.push(t("Campaign Name"));
    if (hasCampaignName) {
      if (String(hasCampaignName).length > 255) {
        !errorFields.includes("Campaign Name") &&
          errorFields.push(t("Campaign Name: Max 255 characters allowed"));
      }
    }

    const services = lodash.get(bd, "cart.services");
    if (!services.length)
      !errorFields.includes("Services") && errorFields.push(t("Services"));

    // nested service validation
    const hasServerIntegrationServices = services?.filter((ser: any) => ser.hasServerIntegration)
    const hasNoServerIntegrationServices = services?.filter((ser: any) => !ser.hasServerIntegration)
    const hasRedirectUrl = hasNoServerIntegrationServices?.every((o: any) => {
      if(o.type === "In-store") return true;
      return isValidUrl(o.redirectUrl);
    }
    );
    const hasRedirectUrlOptional = hasServerIntegrationServices?.every((o: any) =>
      isNotEmptyUrlValid(o.redirectUrl)
    );
    if (!hasRedirectUrl || !hasRedirectUrlOptional)
      !errorFields.includes("Service::Redirect URL") &&
        errorFields.push(t("Redirect URL"));

    const hasStartDate = services.every((o: any) => o.startDate != null);
    if (!hasStartDate)
      !errorFields.includes("Service::Start Date") &&
        errorFields.push(t("Start Date"));

    const hasQuantity = services.every((o: any) => Number(o.quantity) > 0);
    if (!hasQuantity)
      !errorFields.includes("Service::Quantity") &&
        errorFields.push(t("Quantity"));

    const nonMetricServices = services.filter(
      (e: any) => e.durationType !== "Per Metric"
    );
    const hasBasePrice = nonMetricServices.every(
      (o: any) => Number(o.basePrice) > 0
    );
    if (!hasBasePrice)
      !errorFields.includes("Service::Base Price") &&
        errorFields.push(t("Base Price"));

    const metricBasedServices = services.filter(
      (e: any) => e.durationType === "Per Metric"
    );
    const hasCampaignBudget = metricBasedServices.every(
      (o: any) => Number(o?.metricData?.campaignBudget) > 0
    );
    if (!hasCampaignBudget)
      !errorFields.includes("Service::Campaign Budget") &&
        errorFields.push(t("Campaign Budget (SAR)"));

    const hasPositiveDailyBudget = metricBasedServices.every(
      (o: any) =>
        Number(o?.metricData?.dailyBudget) > -1 &&
        Number(o?.metricData?.dailyBudget) <=
        Number(o?.metricData?.campaignBudget)
    );
    if (!hasPositiveDailyBudget)
      !errorFields.includes("Service::Daily Budget") &&
        errorFields.push(t("Daily Budget (SAR)"));

    const hasPositiveMinDuration = metricBasedServices.every(
      (o: any) => Number(o?.metricData?.minDuration) > -1
    );
    if (!hasPositiveMinDuration)
      !errorFields.includes("Service::Min Duration") &&
        errorFields.push(t("Min Duration (Days)"));

    // VALIDATE SKU RELATED REQUIRED FIELDS
    const skuServices = services.filter((o: any) =>
      [
        "SKU Uplifting",
        "Search Bar Ads",
        "Cross Sell",
        "Product Recommendation",
      ].includes(o.type) && !o.hasServerIntegration
    );
    if (skuServices.length > 0) {
      // validate template variables and active native template
      const hasTemplateVariables = skuServices.every((o: any) => {
        // check if all the template vairables are filled
        const vars = lodash.get(o, "activeNativeTemplate.variables", []);

        if (lodash.isEmpty(o?.templateVariables)) return false;

        const templateVariables = lodash.values(o.templateVariables);
        const filledVariables = templateVariables.filter((a) => a);
        if (vars.length != filledVariables.length) return false;

        return true;
        // lodash.values(o.templateVariables).some(lodash.isEmpty)
      });
      if (!hasTemplateVariables)
        !errorFields.includes("Service::Template Variables") &&
          errorFields.push(t("Template Variables"));

      const hasTemplateId = skuServices.every(
        (o: any) => Number(o.templateId) > 0
      );
      if (!hasTemplateId)
        !errorFields.includes("Service::Template Details") &&
          errorFields.push(t("Template Details"));
    }

    const hasServerIntegrationEmptyServices = services.filter((o: any) =>
      o.hasServerIntegration && lodash.isEmpty(o.activeProduct)
    );
    if (!!hasServerIntegrationEmptyServices.length && !errorFields.includes("Product")) {
      errorFields.push(t("Product", { ns: "translationProduct" }));
    }

    const bannerServices = services.filter((o: any) => o.type === "Banner");
    if (bannerServices.length > 0) {
      const hasAltText = bannerServices.every(
        (o: any) =>
          !lodash.isEmpty(o?.altText) && String(o?.altText).length < 255
      );
      if (!hasAltText)
        !errorFields.includes("Service::Alternate Text") &&
          errorFields.push(t("Alternate Text"));
    }

    const popupWindowServices = services.filter(
      (o: any) => o.type === "Popup Window"
    );
    if (popupWindowServices.length > 0) {
      const hasAltText = popupWindowServices.every(
        (o: any) =>
          !lodash.isEmpty(o?.altText) && String(o?.altText).length < 255
      );
      if (!hasAltText)
        !errorFields.includes("Service::Alternate Text") &&
          errorFields.push(t("Alternate Text"));
    }

    // restrict 255 for adTitle
    const allOtherServices = services.filter(
      (o: any) =>
        ![
          "Banner",
          "SKU Uplifting",
          "Search Bar Ads",
          "Cross Sell",
          "Product Recommendation",
        ].includes(o.type)
    );
    if (allOtherServices.length > 0) {
      // check if they have adTitle and their value is > 255
      const hasMoreCharsInAdTitle = allOtherServices.some(
        (o: any) =>
          !lodash.isNull(o?.adTitle) && String(o?.adTitle).length > 255
      );
      if (hasMoreCharsInAdTitle)
        !errorFields.includes("Service::Title") && errorFields.push(t("Title"));

      // check if they have adDescription and their value is > 255
      const hasMoreCharsInAdDescription = allOtherServices.some(
        (o: any) =>
          !lodash.isNull(o?.adDescription) &&
          String(o?.adDescription).length > 255
      );
      if (hasMoreCharsInAdDescription)
        !errorFields.includes("Service::Description") &&
          errorFields.push(t("Description"));
    }

    // @ts-ignore
    if (!lodash.isEmpty(errorFields)) {
      qnotification({
        // @ts-ignore
        message: `[${errorFields.join(", ")}] ${t(
          "Required fields are missing"
        )}`,
        type: "error",
      });
      return false;
    }

    return true;
  };

  const handleSaveAsDraft = async () => {
    setIsLoading(true);
    const payload = generatePayload().payload;
    const response = await updateBriefAsync({ ...payload, submit: false });
    if (response) {
      qnotification({ message: t("Updated Succesfully"), type: "success" });
    }
    setIsLoading(false);
    history.push("/briefs");
    // await getBriefDetails();
  };

  const handleSaveUpdates = async () => {
    if (!validateData()) return;

    setIsLoading(true);
    const payload = generatePayload().payload;

    const response = await updateBriefAsync({ ...payload, submit: true });
    if (response) {
      qnotification({ message: t("Updated Succesfully"), type: "success" });
    }
    setIsLoading(false);

    await getBriefDetails();
  };

  const getServiceCompletionPercentage = (service: any) => {
    let serviceRequiredFields: any = [];

    if (service.type === "Banner") {
      serviceRequiredFields = serviceRequiredFields = [
        "redirectUrl",
        "startDate",
        "imageTargetUrl",
        "quantity",
        "altText",
      ];
    } else if (service.type === "SKU Uplifting") {
      serviceRequiredFields = [
        "startDate",
        "templateId",
        "templateVariables",
        "quantity",
      ];
    } else {
      serviceRequiredFields = ["startDate", "quantity"];
    }

    let filledValues = 0;
    serviceRequiredFields.forEach((s: any) => {
      if (s === "startDate" && service[s] !== null) {
        filledValues++;
      } else if (s === "templateId" && service[s] > 0) {
        filledValues++;
      } else if (s === "quantity" && service[s] > 0) {
        filledValues++;
      } else if (s === "templateVariables" && !lodash.isEmpty(service[s])) {
        filledValues++;
      } else if (s === "imageTargetUrl") {
        if (
          !lodash.isEmpty(service[s]["en"]["desktop"]) ||
          !lodash.isEmpty(service[s]["ar"]["desktop"]) ||
          !lodash.isEmpty(service[s]["en"]["mobile"]) ||
          !lodash.isEmpty(service[s]["ar"]["mobile"])
        ) {
          filledValues++;
        }
      }
    });
    return Number((filledValues / serviceRequiredFields.length) * 100).toFixed(
      0
    );
  };

  const handleCampaignActivation = async () => {
    // check cart, see if the services have banner service, and if that service doesnt have upload material, block it.
    const integratedServices = lodash
      .get(briefResponse, "rawResponse.cart.additional.services")
      .filter((service: any) =>
        ["Banner", "Popup Window"].includes(service.type)
      );

    const hasUploadMaterial = integratedServices.every(
      (o: any) =>
        !lodash.isEmpty(o?.imageTargetUrl?.en?.mobile) ||
        !lodash.isEmpty(o?.imageTargetUrl?.en?.desktop) ||
        !lodash.isEmpty(o?.imageTargetUrl?.ar?.mobile) ||
        !lodash.isEmpty(o?.imageTargetUrl?.ar?.desktop)
    );
    if (!hasUploadMaterial) {
      qnotification({
        message: t("Please add upload material"),
        type: "error",
      });
      return;
    }

    await handleSaveUpdates();

    setIsLoading(true);
    await upsertV3Campaign(briefId);
    getBriefDetails();
    setIsLoading(false);
  };

  const showRequiredLabel = (service: any, control: string) => {
    let serviceRequiredFields: any = [];

    if (["Banner", "Popup Window"].includes(service.type)) {
      serviceRequiredFields = [
        "startDate",
        "image",
        "imageTargetUrl",
        "altText",
      ];
    } else if (
      [
        "SKU Uplifting",
        "Search Bar Ads",
        "Cross Sell",
        "Product Recommendation",
      ].includes(service.type)
    ) {
      serviceRequiredFields = [
        "startDate",
        service.hasServerIntegration ? "productId" : "templateId",
        "templateVariables",
        "quantity",
      ];
    } else {
      serviceRequiredFields = ["startDate", "quantity"];
    }

    if (serviceRequiredFields.includes(control)) {
      return true;
    }

    return false;
  };

  const calculateCaptions = (service: any, type: string) => {
    let c = [];
    if(type === "video"){
      c = [t(".mp4, .mov")];
    }
    else{
      c = [t(".jpg, .jpeg, .png, .gif")];
      if (type == "desktop") {
        if (service?.width > 0 && service?.height > 0) {
          c.push(`${service?.width}w X ${service?.height}h`)
        }
      }
    }
    return c;
  }

  return (
    // @ts-ignore
    <div
      className={`qu-brief-layout full-height-v3 v3-edit-container ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? "html-rtl" : ""
        }`}
    >
      {isLoading && <SpinnerCenter />}
      <div
        className="qu-brief-main pt-25"
        style={{ maxHeight: "max-content", marginBottom: "100px" }}
      >
        <div className="qu-brief-content">
          {/* header */}
          <Row justify="space-between" align="middle">
            <Col>
              <QBackLink
                title={t("Back")}
                onClick={() => history.push("/briefs")}
              />
            </Col>
            <Col>
              {briefResponse?.rawResponse?.status === "DRAFT" && (
                <QButton
                  className={`qu-button-soft ${i18n.dir() === "ltr" ? "mr-20" : "ml-20"
                    }`}
                  htmlType="button"
                  type="primary"
                  size="middle"
                  onClick={handleSaveAsDraft}
                  disabled={isLoading}
                >
                  {t("Save Now, Submit Later")}
                </QButton>
              )}
              <QButton
                htmlType="button"
                type="primary"
                size="middle"
                onClick={handleSaveUpdates}
                disabled={isLoading || !canEdit}
              >
                {t("Submit Campaign Proposal")}
              </QButton>
            </Col>
          </Row>
          {/* header end */}

          <Row gutter={8}>
            {/* Brief components */}
            <Col md={16} xs={24}>
              <Card
                title={
                  <Row>
                    <Col md={12}>{t("Campaign Details")}</Col>
                  </Row>
                }
                className="mt-20 v3-section"
                bordered={true}
              >
                <Row align="middle">
                  <Col md={7} xs={24}>
                    <label className="label-required">
                      {t("Campaign Name")}
                    </label>
                  </Col>
                  <Col md={17} xs={24}>
                    <QInput
                      placeholder={t("Enter here")}
                      size="large"
                      disabled={!canEdit}
                      value={briefData.campaignName}
                      onChange={(e: any) =>
                        handleFormChange("campaignName", e.target.value)
                      }
                      validateTrigger={["onChange", "onBlur"]}
                    />
                  </Col>
                </Row>
                {/*<Divider></Divider>
                                <Row align="middle" className="mt-20">
                                  <Col md={7} xs={24}>
                                    <label>{t("Thumbnail")}</label>
                                  </Col>
                                  <Col md={17} xs={24}>
                                    {
                                      <V3UploadComponent
                                        title={t("Upload Thumbnail")}
                                        captions={[t("Allowed formats .png, .jpeg")]}
                                        briefResponse={briefResponse}
                                        state={thumbnails}
                                        setState={setThumbnails}
                                        path="BRIEF_PRODUCT.productImages"
                                        parentRefresh={getBriefDetails}
                                        canEdit={canEdit}
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                      />
                                    }
                                  </Col>
                                </Row>*/}
                {
                  //@ts-ignore
                  <>
                    <Divider></Divider>
                    <Row align="middle" className="mt-20">
                      <Col md={7} xs={24}>
                        <label className="label-required">
                          {t("Service(s)")}
                        </label>
                      </Col>
                      <Col md={17} xs={24}>
                        {
                          // <V3ServicesComponent
                          //   briefSettings={briefSettings}
                          //   statePublishers={selectedPublishers}
                          //   stateServices={selectedServices}
                          //   setServicesState={setSelectedServices}
                          //   setPublisherState={setSelectedPublishers}
                          //   canEdit={canEdit}
                          //   isLoading={isLoading}
                          //   setIsLoading={setIsLoading}
                          // />
                          <V3ServicesV2Component
                            briefSettings={briefSettings}
                            selectedServices={selectedServices}
                            setSelectedServices={setSelectedServices}
                            canEdit={canEdit}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                          />
                        }
                      </Col>
                    </Row>
                  </>
                }
              </Card>

              {/* dynamic services */}
              {selectedServices.map((service: any, index: number) => {
                return (
                  <Card
                    key={index}
                    title={
                      <Row style={{ alignItems: "center" }}>
                        <Col md={12}>{service.name}</Col>
                        <Col md={12} className="text-right">
                          {["ACCEPTED", "REJECTED"].includes(
                            service.status
                          ) && (
                              <div>
                                <div>
                                  <span
                                    className={`mt-5 status-label ${service.status === "REJECTED"
                                      ? "rejected"
                                      : "approved"
                                      }`}
                                  >
                                    {t(
                                      `requests-status.${String(
                                        service.status
                                      ).toLowerCase()}`,
                                      {
                                        ns: "translationABrief",
                                      }
                                    )}
                                  </span>
                                </div>
                                {service.status === "REJECTED" &&
                                  service.rejectionReason && (
                                    <div className="text-red">
                                      <small style={{ whiteSpace: "normal" }}>
                                        {service.rejectionReason}
                                      </small>
                                    </div>
                                  )}
                              </div>
                            )}
                        </Col>
                      </Row>
                    }
                    className={"mt-10 v3-section"}
                    bordered={true}
                  >
                    {/* Start Date */}
                    <Row align="middle" className="mt-20">
                      <Col md={7} xs={24}>
                        <label className="label-required">
                          {t("Start Date")}
                        </label>
                      </Col>
                      <Col md={17} xs={24}>
                        <QDatePicker
                          // value={briefData.startDate}
                          value={service.startDate}
                          format={FORMATS.DATE_FORMAT}
                          onChange={(d: any) => handleDatePicker(d, index)}
                          disabledDate={disabledPastDates}
                          className="full-width"
                          size="large"
                          placeholder={t("Select starting date")}
                          disabled={!canEdit}
                        />
                      </Col>
                    </Row>
                    <Divider></Divider>

                    {/* Upload Material for banner Ads */}
                    {![
                      "SKU Uplifting",
                      "Search Bar Ads",
                      "Cross Sell",
                      "Product Recommendation",
                      "Video",
                    ].includes(service?.type) && (
                        <>
                          {" "}
                          <Row align="top" className="mt-20">
                            <Col md={7} xs={24}>
                              <div>
                                <label>{t("Upload Material")}</label>
                              </div>
                              <div
                                className={
                                  i18n.dir() === "rtl" ? "pl-30" : "pr-30"
                                }
                              >
                                <small>
                                  {t(
                                    "If you only upload single image for desktop or mobile, it will be used for the other platform as well"
                                  )}
                                </small>
                              </div>
                            </Col>
                            <Col md={17} xs={24}>
                              <Row>
                                <Col md={24} xs={24}>
                                  <div
                                    onClick={(e) =>
                                      handleValueChange(
                                        "showUploadAr",
                                        !service?.showUploadAr,
                                        index
                                      )
                                      // setShowUploadAr(!showUploadAr)
                                    }
                                    className="flex-row secondary-text"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div>
                                      <label>{t("Arabic")}</label>
                                    </div>
                                    <div>
                                      {service?.showUploadAr && <CaretDownOutlined />}
                                      {!service?.showUploadAr && <CaretRightOutlined />}
                                    </div>
                                  </div>

                                  {service?.showUploadAr && (
                                    <>
                                      <div className="mt-10 mb-10"></div>
                                      <div>
                                        <V3UploadBannerMediaComponent
                                          isVideo = {service?.type === "Video"}
                                          accept={service?.type === "Video" ? ".mp4, .webm" : undefined}
                                          arrayIndex={index}
                                          state={selectedServices}
                                          setState={setSelectedServices}
                                          lang={"ar"}
                                          type={"desktop"}
                                          title={t("Upload Media for Desktop")}
                                          captions={calculateCaptions(service, "desktop")}
                                          briefResponse={briefResponse}
                                          parentRefresh={getBriefDetails}
                                          // canEdit={canEdit}
                                          canEdit={lodash.isNull(
                                            lodash.get(
                                              briefResponse,
                                              "rawResponse.campaign"
                                            )
                                          )}
                                          isLoading={isLoading}
                                          setIsLoading={setIsLoading}
                                        />
                                      </div>
                                      <div className="mt-10 mb-10"></div>
                                      <div>
                                        <V3UploadBannerMediaComponent
                                          isVideo = {service?.type === "Video"}
                                          accept={service?.type === "Video" ? ".mp4, .webm" : undefined}
                                          arrayIndex={index}
                                          state={selectedServices}
                                          setState={setSelectedServices}
                                          lang={"ar"}
                                          type={"mobile"}
                                          title={t("Upload Media for Mobile")}
                                          captions={calculateCaptions(service, "mobile")}
                                          briefResponse={briefResponse}
                                          parentRefresh={getBriefDetails}
                                          // canEdit={canEdit}
                                          canEdit={lodash.isNull(
                                            lodash.get(
                                              briefResponse,
                                              "rawResponse.campaign"
                                            )
                                          )}
                                          isLoading={isLoading}
                                          setIsLoading={setIsLoading}
                                        />
                                      </div>
                                    </>
                                  )}
                                </Col>
                              </Row>
                              <div className="mt-10 mb-10"></div>
                              <Row>
                                <Col md={24} xs={24}>
                                  <div
                                    onClick={(e) =>
                                      handleValueChange(
                                        "showUploadEn",
                                        !service?.showUploadEn,
                                        index
                                      )
                                      // setShowUploadAr(!showUploadAr)
                                    }
                                    className="flex-row secondary-text"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div>
                                      <label>{t("English")}</label>
                                    </div>
                                    <div>
                                      {service?.showUploadEn && <CaretDownOutlined />}
                                      {!service?.showUploadEn && <CaretRightOutlined />}
                                    </div>
                                  </div>

                                  {service?.showUploadEn && (
                                    <>
                                      <div className="mt-10 mb-10"></div>
                                      <div>
                                        <V3UploadBannerMediaComponent
                                          isVideo = {service?.type === "Video"}
                                          accept={service?.type === "Video" ? ".mp4, .webm" : undefined}
                                          arrayIndex={index}
                                          state={selectedServices}
                                          setState={setSelectedServices}
                                          lang={"en"}
                                          type={"desktop"}
                                          title={t("Upload Media for Desktop")}
                                          captions={calculateCaptions(service, "desktop")}
                                          briefResponse={briefResponse}
                                          parentRefresh={getBriefDetails}
                                          // canEdit={canEdit}
                                          canEdit={lodash.isNull(
                                            lodash.get(
                                              briefResponse,
                                              "rawResponse.campaign"
                                            )
                                          )}
                                          isLoading={isLoading}
                                          setIsLoading={setIsLoading}
                                        />
                                      </div>
                                      <div className="mt-10 mb-10"></div>
                                      <div>
                                        <V3UploadBannerMediaComponent
                                          isVideo = {service?.type === "Video"}
                                          accept={service?.type === "Video" ? ".mp4, .webm" : undefined}
                                          arrayIndex={index}
                                          state={selectedServices}
                                          setState={setSelectedServices}
                                          lang={"en"}
                                          type={"mobile"}
                                          title={t("Upload Media for Mobile")}
                                          captions={calculateCaptions(service, "mobile")}
                                          briefResponse={briefResponse}
                                          parentRefresh={getBriefDetails}
                                          // canEdit={canEdit}
                                          canEdit={lodash.isNull(
                                            lodash.get(
                                              briefResponse,
                                              "rawResponse.campaign"
                                            )
                                          )}
                                          isLoading={isLoading}
                                          setIsLoading={setIsLoading}
                                        />
                                      </div>
                                    </>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Divider></Divider>
                        </>
                      )}

                    {/* Upload Material for video Ads */}
                    {[
                      "Video",
                    ].includes(service?.type) && (
                        <>
                          {" "}
                          <Row align="top" className="mt-20">
                            <Col md={7} xs={24}>
                              <div>
                                <label>{t("Upload Material")}</label>
                              </div>
                            </Col>
                            <Col md={17} xs={24}>
                              <V3UploadBannerMediaComponent
                                isVideo = {service?.type === "Video"}
                                accept={service?.type === "Video" ? ".mp4, .webm" : undefined}
                                arrayIndex={index}
                                state={selectedServices}
                                setState={setSelectedServices}
                                lang={"ar"}
                                type={"desktop"}
                                title={t("Upload Video")}
                                captions={calculateCaptions(service, "video")}
                                briefResponse={briefResponse}
                                parentRefresh={getBriefDetails}
                                // canEdit={canEdit}
                                canEdit={lodash.isNull(
                                  lodash.get(
                                    briefResponse,
                                    "rawResponse.campaign"
                                  )
                                )}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                              />
                            </Col>
                          </Row>
                          <Divider></Divider>
                        </>
                      )}

                    {/* Ad alt text for banner Ads */}
                    {["Banner", "Popup Window"].includes(service?.type) && (
                      <>
                        <Row align="top" className="mt-20 d-none">
                          <Col md={7} xs={24}>
                            <div>
                              <label
                                className={
                                  showRequiredLabel(service, "altText")
                                    ? "label-required"
                                    : ""
                                }
                              >
                                {t("Alternate Text")}
                              </label>
                            </div>
                            <div
                              className={
                                i18n.dir() === "rtl" ? "pl-30" : "pr-30"
                              }
                            >
                              <small>
                                {t(
                                  "This will be shown if the image fails to load"
                                )}
                              </small>
                            </div>
                          </Col>
                          <Col md={17} xs={24}>
                            <QInput
                              size="large"
                              placeholder={t("Enter here")}
                              disabled={!canEdit}
                              className="full-width"
                              value={
                                briefData?.campaignName ?? service?.altText
                              }
                              onChange={
                                (e: any) =>
                                  handleValueChange(
                                    "altText",
                                    e.target.value,
                                    index
                                  )
                                // handleDescriptionChange(e.target.value, index)
                              }
                            />
                            {String(service?.altText)?.length > 255 && (
                              <div>
                                <small className="text-red">
                                  {t("Max 255 characters allowed")}
                                </small>
                              </div>
                            )}
                          </Col>
                        </Row>
                        <Divider className="d-none"></Divider>
                      </>
                    )}

                    {/* Redirect URL */}
                    <Row align="middle" className="mt-20">
                      <Col md={7} xs={24}>
                        <label className={
                          service.hasServerIntegration || service.type === "In-store"
                            ? ""
                            : "label-required"
                        }>
                          {t("Redirect URL")}
                        </label>
                      </Col>
                      <Col md={17} xs={24}>
                        <QInput
                          size="large"
                          placeholder={t("Enter here")}
                          disabled={!canEdit}
                          className="full-width"
                          value={service.redirectUrl}
                          onChange={(e: any) =>
                            handleUrlChange(e.target.value, index)
                          }
                        />
                        <small>
                          {t(
                            "URLs starting with http:// or  https:// are supported"
                          )}
                        </small>
                      </Col>
                    </Row>
                    <Divider></Divider>

                    {/* Native Template */}
                    {[
                      "SKU Uplifting",
                      "Search Bar Ads",
                      "Cross Sell",
                      "Product Recommendation",
                    ].includes(service?.type) && (
                        service.hasServerIntegration
                          ? <>
                            <Row align="top" className="mt-20">
                              <Col md={7} xs={24}>
                                <label
                                  className={
                                    showRequiredLabel(service, "productId")
                                      ? "label-required"
                                      : ""
                                  }
                                >
                                  {t("Product", { ns: "translationProduct" })}
                                </label>
                              </Col>
                              <Col md={17} xs={24}>
                                <V3ProductComponent
                                  arrayIndex={index}
                                  briefSettings={briefSettings}
                                  state={selectedServices}
                                  setState={setSelectedServices}
                                  canEdit={canEdit}
                                  isLoading={isLoading}
                                  setIsLoading={setIsLoading}
                                  hasServerIntegration={service.hasServerIntegration}
                                />
                              </Col>
                            </Row>
                            <Divider></Divider>
                          </>
                          : <>
                            <Row align="top" className="mt-20">
                              <Col md={7} xs={24}>
                                <label
                                  className={
                                    showRequiredLabel(service, "templateId")
                                      ? "label-required"
                                      : ""
                                  }
                                >
                                  {t("Template")}
                                </label>
                              </Col>
                              <Col md={17} xs={24}>
                                {
                                  <V3NativeTemplatesComponent
                                    arrayIndex={index}
                                    briefSettings={briefSettings}
                                    state={selectedServices}
                                    setState={setSelectedServices}
                                    canEdit={canEdit}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                  />
                                }
                              </Col>
                            </Row>
                            <Divider></Divider>
                          </>
                      )}

                    <div
                      onClick={(e) =>
                        handleValueChange(
                          "showAdvanced",
                          !service?.showAdvanced,
                          index
                        )
                        // setShowUploadAr(!showUploadAr)
                      }
                      className="flex-row pb-20 primary-text"
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <label >{t("Show Advanced Settings")}</label>
                      </div>
                      <div>
                        {service?.showAdvanced && <CaretDownOutlined />}
                        {!service?.showAdvanced && <CaretRightOutlined />}
                      </div>
                    </div>
                    {service?.showAdvanced && (
                      <>
                        {/* Targeting Type */}
                        <Row align="middle" className="mt-20">
                          <Col md={7} xs={24}>
                            <div>
                              <label>{t("Targeting Type")}</label>
                            </div>
                          </Col>
                          <Col md={17} xs={24}>
                            <Radio.Group
                              disabled={!canEdit}
                              onChange={(e: any) => {
                                const ss: any = [...selectedServices];
                                if (e.target.value === "keywords") {
                                  ss[index]["targetingLogic"] = [];
                                } else {
                                  ss[index]["targeting"] = {};
                                }
                                ss[index]["targetingType"] = e.target.value;
                                setSelectedServices(ss);
                              }}
                              value={service?.targetingType}
                            >
                              <Radio value={"keywords"}>{t("Keywords")}</Radio>
                              <Radio value={"advanced"}>{t("Advanced")}</Radio>
                            </Radio.Group>
                          </Col>
                        </Row>
                        <Divider></Divider>

                        {/* Targeting Keywords */}
                        {service?.targetingType === "keywords" && (
                          <>
                            <Row align="middle" className="mt-20">
                              <Col md={7} xs={24}>
                                <Row>
                                  <Col>
                                    <label>{t("Keywords")}</label>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={17} xs={24}>
                                {
                                  <V3TargetingComponent
                                    briefSettings={briefSettings}
                                    arrayIndex={index}
                                    state={selectedServices}
                                    setState={setSelectedServices}
                                    canEdit={canEdit}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                  />
                                }
                              </Col>
                            </Row>
                            <Divider></Divider>
                          </>
                        )}

                        {/* Targeting advanced */}
                        {service?.targetingType === "advanced" && (
                          <>
                            <Row align="middle" className="mt-20">
                              <Col md={7} xs={24}>
                                <label>{t("Advanced Targeting")}</label>
                              </Col>
                              <Col md={17} xs={24}>
                                <V3LogicComponent
                                  briefSettings={briefSettings}
                                  arrayIndex={index}
                                  state={selectedServices}
                                  setState={setSelectedServices}
                                  canEdit={canEdit}
                                  isLoading={isLoading}
                                  setIsLoading={setIsLoading}
                                />
                              </Col>
                            </Row>
                            <Divider></Divider>
                          </>
                        )}

                        {/* Ad Title for banner Ads */}
                        {![
                          "Popup Window",
                          "SKU Uplifting",
                          "Banner",
                          "Search Bar Ads",
                          "Cross Sell",
                          "Product Recommendation",
                        ].includes(service?.type) && (
                            <>
                              {" "}
                              <Row align="top" className="mt-20">
                                <Col md={7} xs={24}>
                                  <label
                                    className={
                                      showRequiredLabel(service, "adTitle")
                                        ? "label-required"
                                        : ""
                                    }
                                  >
                                    {t("Title")}
                                  </label>
                                </Col>
                                <Col md={17} xs={24}>
                                  <QInput
                                    size="large"
                                    placeholder={t("Enter here")}
                                    disabled={!canEdit}
                                    className="full-width"
                                    value={service?.adTitle}
                                    onChange={
                                      (e: any) =>
                                        handleValueChange(
                                          "adTitle",
                                          e.target.value,
                                          index
                                        )
                                      // handleTitleChange(e.target.value, index)
                                    }
                                  />
                                  {String(service?.adTitle)?.length > 255 && (
                                    <div>
                                      <small className="text-red">
                                        {t("Max 255 characters allowed")}
                                      </small>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                              <Divider></Divider>
                            </>
                          )}
                      </>
                    )}

                    {/* Ad Description for banner Ads */}
                    {/*![
                      "SKU Uplifting",
                      "Banner",
                      "Search Bar Ads",
                      "Cross Sell",
                      "Product Recommendation",
                    ].includes(service?.type) && (
                      <>
                        <Row align="top" className="mt-20">
                          <Col md={7} xs={24}>
                            <label
                              className={
                                showRequiredLabel(service, "adDescription")
                                  ? "label-required"
                                  : ""
                              }
                            >
                              {t("Description")}
                            </label>
                          </Col>
                          <Col md={17} xs={24}>
                            <QInput
                              size="large"
                              placeholder={t("Enter here")}
                              disabled={!canEdit}
                              className="full-width"
                              value={service?.adDescription}
                              onChange={
                                (e: any) =>
                                  handleValueChange(
                                    "adDescription",
                                    e.target.value,
                                    index
                                  )
                                // handleDescriptionChange(e.target.value, index)
                              }
                            />
                            {String(service?.adDescription)?.length > 255 && (
                              <div>
                                <small className="text-red">
                                  {t("Max 255 characters allowed")}
                                </small>
                              </div>
                            )}
                          </Col>
                        </Row>
                        <Divider></Divider>
                      </>
                    )*/}
                  </Card>
                );
              })}
              {/* dynamic services end */}
            </Col>
            {/* Brief components end */}

            {/* Cart */}
            <Col md={8} xs={24}>
              <Card
                title={t("Cart Details")}
                className="mt-20 v3-section"
                bordered={true}
              >
                {
                  <V3CartComponent
                    selectedServicesState={selectedServices}
                    setSelectedServicesState={setSelectedServices}
                    cartState={cart}
                    setCartState={setCart}
                    briefResponse={briefResponse}
                    parentRefresh={getBriefDetails}
                    saveUpdates={handleSaveUpdates}
                    validateData={validateData}
                  />
                }
              </Card>

              {/* payment methods */}
              {!lodash.isNull(
                lodash.get(briefResponse, "rawResponse.payment")
              ) && (
                  <Card
                    title={
                      <Row>
                        <Col md={12}>{t("Payment Details")}</Col>
                        <Col md={12} className="text-right">
                          <span
                            className={`status-label ${
                              // @ts-ignore
                              BRIEF_PAYMENT_STATUS[
                              lodash.get(
                                briefResponse,
                                "rawResponse.payment.status"
                              )
                              ] &&
                              // @ts-ignore
                              briefPaymentStatuses[
                                lodash.get(
                                  briefResponse,
                                  "rawResponse.payment.status"
                                )
                              ].classStyle
                              }`}
                          >
                            {t(
                              `brief.payment-statuses-array.${String(
                                briefResponse?.rawResponse?.payment?.status
                              ).toUpperCase()}`,
                              { ns: "translation" }
                            )}
                          </span>
                        </Col>
                      </Row>
                    }
                    className="mt-10 v3-section"
                    bordered={true}
                  >
                    {
                      <V3PaymentMethodsComponent
                        briefResponse={briefResponse}
                        parentRefresh={getBriefDetails}
                        isAdmin={true}
                      />
                    }
                  </Card>
                )}

              {lodash.isNull(briefResponse?.rawResponse?.campaign) && (
                <QButton
                  htmlType="button"
                  size="large"
                  className="full-width justify-center qu-button-soft qu-button-green mt-20"
                  disabled={!enableCampaignButton}
                  onClick={() => setModalOpen(true)}
                >
                  {t("Activate Campaign")}
                </QButton>
              )}

              {!lodash.isNull(briefResponse?.rawResponse?.campaign) && (
                <QButton
                  htmlType="button"
                  className="full-width justify-center qu-button-soft mt-15"
                  type="primary"
                  onClick={() =>
                    history.push(
                      `/campaigns/v3/${briefResponse?.rawResponse?.campaign?.id}`
                    )
                  }
                >
                  {t("View Campaign Details")}
                </QButton>
              )}
            </Col>
            {/* Cart ends */}
          </Row>
        </div>
      </div>

      {(!lodash.isEmpty(briefResponse) ||
        !lodash.isNull(briefResponse) ||
        !lodash.isUndefined(briefResponse)) && (
          <Row id={BRIEF_PRINT_ID}>
            <Col span={12} offset={6} id="brief_quotation_print_area" hidden>
              <BriefQuotationV3 briefResponse={briefResponse} enableTax={false} />
            </Col>
            <Col
              span={12}
              offset={6}
              id="brief_quotation_print_area_with_tax"
              hidden
            >
              <BriefQuotationV3 briefResponse={briefResponse} enableTax={true} />
            </Col>
          </Row>
        )}

      <DialogModal
        modalOpen={modalOpen}
        question={t(`Are you sure?`)}
        description={t(`This action is not reversible`)}
        submitButtonText={t(`cancel`)}
        cancelButtonText={t(`confirm`)}
        onClose={() => {
          setModalOpen(false);
        }}
        onProceed={async () => {
          await handleCampaignActivation();
          setModalOpen(false);
        }}
        revert
      />
    </div>
  );
}
