import {
  Col,
  QButton,
  QInput,
  QTable,
  Row,
  Modal,
  qnotification,
} from "quantum_components";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SpinnerCenter from "src/components/Spinner/spinner";
import {
  createPublisher,
  deletePublisher,
  getAllPublishersList,
  updatePublisher,
} from "../publishers.actions";
import lodash from "lodash";
import { Pagination, Tooltip } from "antd";
import {
  EyeOutlined,
  AppstoreOutlined,
  DeleteOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined
} from "@ant-design/icons";
import i18n from "src/i18n";
import DialogModal from "src/components/DialogModal/DialogModal";
import { useSelector } from "react-redux";
import { getCurrentUserPermissionActions } from "src/modules/core/core.selectors";
import { hasPermission } from "src/helpers/permission";
import { PERMISSIONS } from "src/constants/permission";
import UploadComponent from "src/components/UploadComponent";
import { upload, uploadGenericFile } from "src/modules/core/core.actions";
import { getFileNameFromUrl } from "src/utils";

const PublishersContainer = () => {
  const { t } = useTranslation(["translationPublisher"]);
  const [isLoading, setIsLoading] = useState(false);
  const [publishersList, setPublishersList] = useState([]);
  const [search, setSearch] = useState("");
  const [debounce, setDebounce] = useState<any>(-1);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const [selectedPublisher, setSelectedPublisher] = useState<any>({
    id: -1,
    companyLogo: "",
    companyName: "",
    email: "",
    password:"",
    mediaId: -1,
  });
  const [isEdit, setIsEdit] = useState(false);
  const currentUserPermissions = useSelector(getCurrentUserPermissionActions);

  const [pagination, setPagination] = useState<any>({
    offset: 0,
    limit: 10,
    direction: "desc",
    sortField: "id",
    search: "",
    total: 0,
  });

  useEffect(() => {
    fetchPublishersList(pagination);
  }, []);

  const handlePublisherAction = async (action: string) => {
    if (action === "DELETE" && selectedPublisher?.id > 0) {
      await deletePublisher(selectedPublisher?.id);
      await fetchPublishersList(pagination);
    }

    resetSelectedPublisher();
  };

  const columns = [
    {
      title: t("Company Name"),
      width: "35%",
      align: i18n.dir() === "ltr" ? "left" : "right",
      dataIndex: "companyName",
      key: "companyName",
      fixed: "left",
      sorter: true,
      render: (e: any, row: any) => {
        return(
          <div className="q-table-product">
            <div className="q-table-product_thumb">
                          {row.companyLogo && (
                            <img src={row.companyLogo} alt={row.companyName} />
                          )}
                        </div>
            <div className="q-table-product_info">
              <h3 className="q-table-product_title">
                {row.companyName}
              </h3>
            </div>
          </div>
        )
      }
    },
    {
      title: t("Email"),
      dataIndex: "email",
      key: "email",
      width: "25%",
      align: i18n.dir() === "ltr" ? "left" : "right",
    },
    {
      title: t("Campaign Count"),
      dataIndex: "campaignCount",
      key: "campaignCount",
      width: "20%",
      align: i18n.dir() === "ltr" ? "left" : "right",
      sorter: true,
    },
    {
      title: t("Actions"),
      width: "20%",
      align: i18n.dir() === "ltr" ? "left" : "right",
      render: (element: any, row: any) => {
        return (
          <div>
            {hasPermission(
              currentUserPermissions,
              PERMISSIONS.UPDATE_PUBLISHER
            ) && (
              <Tooltip title={t("Edit Publisher")}>
                <EyeOutlined
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEdit(true);
                    setSelectedPublisher({
                      ...selectedPublisher,
                      id: element?.id,
                      email: element?.email,
                      mediaId: element?.mediaId,
                      companyName: element?.companyName,
                      companyLogo: element?.companyLogo,
                    });
                    setFiles([
                      ...[
                        {
                          ...{
                            uid: 1,
                            name: getFileNameFromUrl(element?.companyLogo),
                            status: "done",
                            url: element?.companyLogo,
                          },
                        },
                      ],
                    ]);
                    setShowModal(true);
                  }}
                />
              </Tooltip>
            )}
            {/*<Tooltip title={t("View Campaigns")}>
                          <AppstoreOutlined
                            className={i18n.dir() === "ltr" ? "ml-10" : "mr-10"}
                          />
                        </Tooltip>*/}
            {hasPermission(
              currentUserPermissions,
              PERMISSIONS.DELETE_PUBLISHER
            ) && (
              <Tooltip title={t("Delete Publisher")}>
                <DeleteOutlined
                  className={i18n.dir() === "ltr" ? "ml-10" : "mr-10"}
                  onClick={async (e) => {
                    e.preventDefault();
                    setSelectedPublisher({
                      ...selectedPublisher,
                      id: element?.id,
                    });
                    setShowConfirm(true);
                  }}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const fetchPublishersList = async (pagination: any) => {
    setIsLoading(true);
    const data = await getAllPublishersList(pagination);
    setPublishersList(lodash.get(data, "results", []));
    setPagination({ ...pagination, total: lodash.get(data, "total", 0) });
    setIsLoading(false);
  };

  const resetPagination = () => {
    const defaultPagination: any = {
      offset: 0,
      limit: 10,
      direction: "desc",
      sortField: "id",
      search: "",
      total: 0,
    };
    setPagination(defaultPagination);
    return defaultPagination;
  };

  const handleTableChange = (page: any, filters: any, sorter: any) => {
    let column = sorter?.column?.key ?? "id";
    let direction = sorter?.order ?? "desc";

    if (direction === "descend") {
      direction = "desc";
    } else {
      direction = "asc";
    }

    let existingPagination = { ...pagination, direction, sortField: column };

    setPagination(existingPagination);
    fetchPublishersList(existingPagination);
  };

  const resetSelectedPublisher = () => {
    setSelectedPublisher({
      id: -1,
      companyLogo: "",
      companyName: "",
      email: "",
      mediaId: -1,
    });
    setFiles([]);
    setIsEdit(false);
  };

  const handleChangePage = async (page: number, pageSize: number) => {
    const dp = { ...pagination };
    dp.offset = (page - 1) * dp.limit;
    await fetchPublishersList(dp);
  };

  useEffect(() => {
    if (!search && debounce < 0) return;
    if (debounce) clearTimeout(debounce);

    setDebounce(
      setTimeout(() => {
        let dp = resetPagination();
        dp.search = search;

        setPagination(dp);

        fetchPublishersList(dp);
      }, 500)
    );
  }, [search]);

  const handleUpload = async (uploads: File[]) => {
    handleRemove();

    setIsLoading(true);
    const uploadRes = await uploadGenericFile(uploads[0], "publisher");
    setIsLoading(false);

    if (uploadRes.length > 0) {
      setFiles([
        ...uploadRes.map((f: any, index: number) => {
          return {
            uid: index + "",
            name: getFileNameFromUrl(f.src),
            status: "done",
            url: f.src,
          };
        }),
      ]);
      setSelectedPublisher({
        ...selectedPublisher,
        mediaId: uploadRes[0].mediaId,
      });
    }
  };

  const handleRemove = async () => {
    setSelectedPublisher({ ...selectedPublisher, mediaId: -1 });
    setFiles([]);
  };

  const handleSave = async () => {
    if (
      !selectedPublisher.companyName ||
      !selectedPublisher.email ||
      !selectedPublisher.password ||
      selectedPublisher.password.length < 8 ||
      selectedPublisher.mediaId <= 0
    ) {
      qnotification({ type: "error", message: t("Missing required fields") });
      return;
    }

    setIsLoading(true);

    let res = null;
    if (!isEdit) {
      delete selectedPublisher.id;
      delete selectedPublisher.companyLogo;
      res = await createPublisher(selectedPublisher);
    } else {
      // udpate existing
      delete selectedPublisher.companyLogo;
      res = await updatePublisher(selectedPublisher);
    }

    setIsLoading(false);
    if (!res) return;

    await fetchPublishersList(pagination);
    setShowModal(false);
    resetSelectedPublisher();
  };

  return (
    <div className="services-v2">
      {isLoading && <SpinnerCenter />}

      {
        <Row justify="space-between" className="mt-20">
          <Col>
            <h2>{t("Publishers")}</h2>
          </Col>
        </Row>
      }

      <Row justify="space-between" align={"middle"} className="pb-10">
        <Col md={12} className="flex-container-row-flex-start">
          <div>
            <QInput
              placeholder={t("Search")}
              size="large"
              className={"w-400"}
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
            />
          </div>
        </Col>
        <Col>
          <div className="flex-row" style={{ gap: "20px" }}>
            <div className="text-bold">
              {t("Total Available Publishers")}: {pagination?.total}
            </div>
            {hasPermission(
              currentUserPermissions,
              PERMISSIONS.CREATE_PUBLISHER
            ) && (
              <div>
                <QButton
                  size="small"
                  type="primary"
                  htmlType="button"
                  className="xs-btn"
                  onClick={() => setShowModal(true)}
                >
                  +&nbsp;{t("Add Publisher")}
                </QButton>
              </div>
            )}
          </div>
        </Col>
      </Row>

      {
        <Row>
          <Col md={24}>
            <div className="pb-10">
              <QTable
                // @ts-ignore
                onChange={handleTableChange}
                columns={columns}
                dataSource={publishersList}
                loading={false}
                scroll={{ x: "auto" }}
              />
            </div>
            <div className="qt-footer">
              <Pagination
                showSizeChanger={false}
                size="small"
                defaultCurrent={1}
                current={
                  pagination?.offset / pagination?.limit == 0
                    ? 1
                    : pagination?.offset / pagination?.limit + 1
                }
                total={pagination?.total}
                defaultPageSize={pagination?.limit}
                onChange={handleChangePage}
              />
            </div>
          </Col>
        </Row>
      }

      <DialogModal
        modalOpen={showConfirm}
        question={t("Are you sure?")}
        description={t("This action is irreversible.")}
        submitButtonText={t("Cancel")}
        cancelButtonText={t("Confirm")}
        onClose={() => {
          setShowConfirm(false);
          resetSelectedPublisher();
        }}
        onProceed={async () => {
          setShowConfirm(false);
          await handlePublisherAction("DELETE");
        }}
        revert
      />

      <Modal
        wrapClassName={`qu-modal ${i18n.dir() === "rtl" ? "html-rtl" : ""}`}
        title={
          <h3>{t(`${isEdit ? t("Edit") : t("Create")} ${t("Publisher")}`)}</h3>
        }
        visible={showModal}
        centered
        width={"min('450px',90%)"}
        onCancel={() => {
          resetSelectedPublisher();
          setShowModal(false);
        }}
        footer={false}
      >
        <div className="mb-10">
          <div>
            <label className="text-bold label-required">
              {t("Company Name")}
            </label>
          </div>
          <div>
            <QInput
              size="large"
              placeholder={t("Enter here")}
              // disabled={!canEdit}
              className="full-width"
              value={selectedPublisher?.companyName}
              onChange={(e: any) =>
                setSelectedPublisher({
                  ...selectedPublisher,
                  companyName: e.target.value,
                })
              }
            />
            {selectedPublisher?.companyName?.length >= 255 && (
              <small className="text-red">
                {t("Max 255 characters allowed")}
              </small>
            )}
          </div>
        </div>
        <div className="mb-10">
          <div>
            <label className="text-bold label-required">{t("Email")}</label>
          </div>
          <div>
            <QInput
              htmlType="email"
              size="large"
              placeholder={t("Enter here")}
              // disabled={!canEdit}
              className="full-width"
              value={selectedPublisher?.email}
              onChange={(e: any) =>
                setSelectedPublisher({
                  ...selectedPublisher,
                  email: e.target.value,
                })
              }
            />
            {selectedPublisher?.email?.length >= 255 && (
              <small className="text-red">{t("Valid email is required")}</small>
            )}
          </div>
        </div>
        <div className="mb-10">
          <div>
            <label className="text-bold label-required">{t("Password")}</label>
          </div>
          <div>
            <QInput
              htmlType="pasword"
              size="large"
              placeholder={t("Enter here")}
              // disabled={!canEdit}
              className="full-width"
              value={selectedPublisher?.password}
              onChange={(e: any) =>
                setSelectedPublisher({
                  ...selectedPublisher,
                  password: e.target.value,
                })
              }
            />
            {selectedPublisher?.password?.length < 8 && selectedPublisher?.password?.length > 0  && (
              <small className="text-red">{t("Atleast 8 characters are required")}</small>
            )}
          </div>
        </div>
        <div className="mb-10">
          <div>
            <label className="text-bold label-required">
              {t("Company Logo")}
            </label>
          </div>
          <div>
            <UploadComponent
              maxCount={2}
              files={files}
              onChange={handleUpload}
              onRemove={handleRemove}
              isUploading={isLoading}
              heading={t("Upload Logo")}
              accept={".png,.jpeg,.jpeg"}
              caption={[t("Supported formats: png, jpeg, jpg")]}
            />
          </div>
        </div>
        <div className="mt-20">
          <QButton
            size={"large"}
            type="primary"
            htmlType="button"
            className="full-width"
            onClick={handleSave}
          >
            <div className="flex-row" style={{gap:"5px"}}>
              <div>{isEdit?t("Save Update"):t("Create Publisher")}</div>
              <div>{i18n.dir() === 'ltr'? <ArrowRightOutlined />:<ArrowLeftOutlined />}</div>
            </div>
          </QButton>
        </div>
      </Modal>
    </div>
  );
};

export default PublishersContainer;
