import { Col, Row, Tag } from "antd";
import { AgeGroupCard } from "src/modules/brief/containers/components/Brief.AgeGroupCard";
import lodash, { assign, divide, filter } from "lodash";
import React, { useEffect, useState } from "react";
import SpinnerCenter from "src/components/Spinner/spinner";
import { useTranslation } from "react-i18next";
import { BANNERS_BY_MEDIA_TYPE, MEDIA_TYPES } from "src/constants/brief";
import DialogModal from "src/components/DialogModal/DialogModal";
import {
  Modal,
  QInput,
  QSelect,
  QButton,
  qnotification,
  QBackLink,
  QSelectItem,
} from "quantum_components";
import i18n from "src/i18n";
import { useSelector } from "react-redux";

const V3ServicesV2Component = ({
  briefSettings,
  selectedServices,
  setSelectedServices,
  canEdit,
  isLoading,
  setIsLoading,
}: any) => {
  const { t } = useTranslation(["translationV2", "translation"]);
  const [publishers, setPublishers] = useState<any>([]);
  const [filteredPublishers, setFilteredPublishers] = useState<any>([]);
  const [selectedPublishers, setSelectedPublishers] = useState<any>([]);
  const [servicesWithoutPublisher, setServicesWithoutPublisher] = useState<any>(
    []
  );
  const [tableKey, setTableKey] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showPublisherSection, setShowPublisherSection] = useState(false);
  const [showServiceSection, setShowServiceSection] = useState(false);
  const [search, setSearch] = useState("");
  const [servicesSelectionEdit, setServicesSelectionEdit] = useState(true);

  const [isSelfPublisher, setIsSelfPublisher] = useState(false);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (briefSettings?.partnerProfileId == 0) {
      return;
    }

    let _publishers = briefSettings?.publishers?.map((p: any) => {
      return {
        id: p?.id,
        companyName: p?.companyName,
        publisherLogo: p?.publisherLogo?.src,
      };
    });
    const isSelfPublisher = _publishers.length == 0 ? true : false;
    if (isSelfPublisher) {
      _publishers = [
        {
          id: -1,
          companyName: "QRM",
          publisherLogo: "https://placehold.co/150x150?text=Not+Found",
        },
      ];
    }

    // setPublishers(_publishers);
    // setFilteredPublishers(_publishers);
    transformServicesByPublishers(_publishers, isSelfPublisher);
    // transformServicesWithoutPublisher();

    selectDefaultServiceWithPublisher(_publishers)
  }, [briefSettings]);

  // check and select default service which has 
  const selectDefaultServiceWithPublisher = async (publishers: any) => {
    if (publishers.length === 1) {
      const defaultService = await briefSettings?.availableServices?.find((service: any) => service.publisherId === publishers[0].id && service.hasServerIntegration)
      if (defaultService) {
        if (!selectedServices.length) {
          await handlePublisherDefaultSelection()
          await handleServiceChange('', defaultService)
        }
      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (selectedServices.length === 1 && briefSettings?.availableServices?.find((service: any) => service.publisherId === publishers[0].id && service.id === selectedServices[0].id) && briefSettings?.availableServices?.filter((service: any) => service.publisherId === publishers[0].id).length === 1) {
      setServicesSelectionEdit(false)
    } else {
      setServicesSelectionEdit(true)
    }
  }, [selectedServices])

  const transformServicesByPublishers = (
    publishers: any,
    isSelfPublisher: boolean
  ) => {
    let _publishers = [];

    if (!isSelfPublisher) {
      _publishers = [...publishers].map((p: any) => {
        const assignedServices = briefSettings?.availableServices?.filter(
          (s: any) => lodash.get(s, "publisherId") === p.id
        );
        const rawServiceTypes = Array.from(
          new Set(assignedServices.map((as: any) => as.serviceName))
        );
        const availableServices = rawServiceTypes.map((rst: any) => {
          return {
            type: rst,
            services: assignedServices.filter(
              (as: any) => as.serviceName === rst
            ),
          };
        });
        return {
          ...p,
          availableServices,
        };
      });
    } else {
      _publishers = [...publishers].map((p: any) => {
        const assignedServices = briefSettings?.availableServices;
        const rawServiceTypes = Array.from(
          new Set(assignedServices.map((as: any) => as.serviceName))
        );
        const availableServices = rawServiceTypes.map((rst: any) => {
          return {
            type: rst,
            services: assignedServices.filter(
              (as: any) => as.serviceName === rst
            ),
          };
        });
        return {
          ...p,
          availableServices,
        };
      });
    }

    setPublishers(_publishers);
    setFilteredPublishers(_publishers);
    setIsSelfPublisher(isSelfPublisher);

    // setShowPublisherSection(_publishers.length > 0 ? true : false);
  };

  const transformServicesWithoutPublisher = () => {
    const services = [...briefSettings?.availableServices];
    const rawServiceTypes = Array.from(
      new Set(services.map((s: any) => s.serviceName))
    );
    const availableServices = rawServiceTypes.map((rst: any) => {
      return {
        type: rst,
        services: services.filter(
          (as: any) => as.serviceName === rst && lodash.isNull(as.publisherId)
        ),
      };
    });
    setServicesWithoutPublisher(availableServices);
    if (!showPublisherSection) {
      setShowServiceSection(true);
    }
  };

  const handleServiceChange = async (e: any, service: any) => {
    let s = [...selectedServices];

    const serviceObj = {
      hasServerIntegration: service?.hasServerIntegration,
      width: service?.width ?? 0,
      height: service?.height ?? 0,

      mappedTemplate: service?.serviceTemplate ?? null,
      productId: -1,
      activeProduct: {},

      showUploadAr: true,
      showUploadEn: false,
      showAdvanced: false,

      id: service.id,
      publisherId: service.publisherId || null,
      name: service.serviceSubName,
      type: service.serviceName,
      startDate: null,
      targetingType: "keywords",
      targeting: {},
      targetingPriority: "CASUAL",
      targetingLogic: [],

      templateId: -1,
      templateVariables: {},
      activeNativeTemplate: {},

      durationType: service.serviceExecutionType,
      quantity: 1,
      impressionsPerDay: service.serviceImpressions ?? 0,
      basePrice:
        service.serviceExecutionType !== "Per Metric"
          ? Number(service.servicePricePerExecution)
          : 0,
      total:
        service.serviceExecutionType !== "Per Metric"
          ? 1 * Number(service.servicePricePerExecution)
          : 0,
      status: "PENDING",
      rejectionReason: null,
      redirectUrl: null,
      adTitle: null,
      adDescription: null,
      altText: null,
      metricData: {
        metricType: "CPM",
        campaignBudget: 0,
        dailyBudget: 0,
        minDuration: 0,

        totalQuantity: 0,
        servedQuantity: 0,
        estimatedDaysForTotalQuantity: 0,
        dailyQuantity: 0,
        estimatedDaysForDailyQuantity: 0,
        dailyDistribution: [],
      },
      pricePerMetric: {
        cpm: service.servicePricePerMetric?.cpm ?? 0,
        cpc: service.servicePricePerMetric?.cpc ?? 0,
      },
      imageTargetUrl: {
        en: {
          mobile: null,
          desktop: null,
        },
        ar: {
          mobile: null,
          desktop: null,
        },
      },
    };

    // if (s.length == 0) {
    //   s.push(serviceObj);
    // } else {
    //   const existingService = s.find((o) => o.name === service.serviceSubName);
    //   if (existingService) {
    //     s = s.filter((o) => o.name !== existingService.name);
    //   } else {
    //     s.push(serviceObj);
    //   }
    // }
    const existingService = s.find((o) => o.name === service.serviceSubName);
    if (existingService) {
      const existingServiceIndex = s.findIndex((o) => o.id === service.id);
      s.splice(existingServiceIndex, 1);
      // s = s.filter((o) => o.name !== existingService.name);
    } else {
      s.push(serviceObj);
    }
    setSelectedServices(s);
  };

  const generateSublabelString = (service: any) => {
    let publisher = null;
    if (service.publisherId) {
      publisher = lodash.find(
        briefSettings?.publishers,
        (p) => p.id === service.publisherId
      );
    }
    let str = `${publisher ? publisher.companyName : service?.serviceName}\n\n${service?.serviceDescription
      }\n\n`;
    if (service?.serviceExecutionType === "Per Metric") {
      str += ` SAR ${service?.servicePricePerMetric?.cpm} ${t("Per CPM")}\n`;
      str += ` SAR ${service?.servicePricePerMetric?.cpc} ${t("Per CPC")}`;
    } else {
      str += ` SAR ${service?.servicePricePerExecution} ${t(
        service?.serviceExecutionType
      )}`;
    }
    return str;
  };

  const getAvailableServicesFromSelectedPublishers = () => {
    let assignedServices: any = [];

    if (!isSelfPublisher) {
      assignedServices =
        briefSettings?.availableServices?.filter((s: any) =>
          selectedPublishers.includes(lodash.get(s, "publisherId"))
        ) ?? [];
    } else {
      assignedServices = briefSettings?.availableServices ?? [];
    }

    const rawServiceTypes = Array.from(
      new Set(assignedServices.map((as: any) => as.serviceName))
    );
    const availableServices: any = rawServiceTypes.map((rst: any) => {
      return {
        type: rst,
        services: assignedServices.filter((as: any) => as.serviceName === rst),
      };
    });
    return availableServices ?? [];
  };

  useEffect(() => {
    if (search.length == 0) {
      setFilteredPublishers(publishers);
      return;
    }

    const filtered = publishers.filter((s: any) => {
      const companyName = String(s.companyName).toLowerCase().trim();
      return [companyName].join(" ").includes(search.toLowerCase().trim());
    });
    setFilteredPublishers(filtered);
  }, [search]);

  const handlePublisherDefaultSelection = async () => {
    if (publishers.length == 1) {
      setSelectedPublishers([publishers[0]?.id]);
      setShowPublisherSection(false);
      setShowServiceSection(true);
    } else {
      setSelectedPublishers(
        Array.from(
          new Set([...selectedServices?.map((s: any) => s.publisherId)])
        )
      );
      setShowPublisherSection(true);
      setShowServiceSection(false);
    }
  };

  return (
    <div>
      {selectedServices.length == 0 && (
        <div>
          {t("No services found")}{" "}
          <a
            onClick={(e) => {
              if (canEdit) {
                setShowModal(true);

                handlePublisherDefaultSelection();
              }
            }}
          >
            {t("Add Now.")}
          </a>
        </div>
      )}

      <div className={`${selectedServices.length > 0 ? "mt-10" : "mt-0"}`}>
        {selectedServices.map((t: any, index: number) => {
          return (
            t && (
              <Tag
                className="mb-5 tag-preview"
                key={index}
                onClick={(e) => {
                  if (canEdit && servicesSelectionEdit) {
                    setShowModal(true);
                    handlePublisherDefaultSelection();
                  }
                }}
              >
                {t.name}
              </Tag>
            )
          );
        })}
      </div>

      <Modal
        wrapClassName={`v3-modal ${i18n.dir() === "rtl" ? "html-rtl" : ""}`}
        visible={showModal}
        footer={false}
        closable={true}
        title={
          <h3>{showPublisherSection ? t("Publishers") : t("Edit Services")}</h3>
        }
        width={`${document.body.clientWidth} !important`}
        onCancel={(e) => {
          e.stopPropagation();
          setShowModal(false);
        }}
      >
        <>
          {showPublisherSection && (
            <>
              <Row align="middle" justify={"center"}>
                <Col md={15} sm={24} xs={24}>
                  <h6 className="m-0 p-0">
                    {t("Select publishers to see their services")}
                  </h6>
                  <small>
                    {t("You can select more than one publisher in the same campaign")}
                  </small>
                </Col>
                <Col md={9} sm={24} xs={24}>
                  <QInput
                    size="large"
                    value={search}
                    placeholder={t("Search publishers")}
                    onChange={(e: any) => setSearch(e.target.value)}
                  />
                </Col>
              </Row>

              <Row className="mt-20" style={{ gap: "20px" }} key={tableKey}>
                {filteredPublishers?.map((publisher: any, index: number) => {
                  const image = publisher?.publisherLogo;
                  const isChecked = selectedPublishers.includes(publisher.id);
                  return (
                    <Col
                      key={index}
                      className="brief-v3-services-container publisher-marketplace"
                    >
                      <QSelectItem
                        className={"mb-16 age-card"}
                        label={""}
                        sublabel={publisher.companyName}
                        checked={isChecked}
                        cardImage={
                          image || "" ? (
                            <img src={image || ""} alt={""} />
                          ) : (
                            <div />
                          )
                        }
                        onChange={(e: any) => {
                          e.preventDefault();
                          let pubs = [...selectedPublishers];
                          if (pubs.includes(publisher.id)) {
                            pubs = lodash.pull(pubs, publisher.id);
                            // remove all selected services;

                            const ss = [...selectedServices];
                            setSelectedServices(
                              ss.filter(
                                (s: any) => s.publisherId != publisher.id
                              )
                            );
                          } else {
                            pubs.push(publisher.id);
                          }
                          setSelectedPublishers(pubs);
                          setTableKey((tk) => tk + 1);
                        }}
                      />
                    </Col>
                  );
                })}
              </Row>

              <Row className="mt-10">
                <Col md={24}>
                  <QButton
                    disabled={selectedPublishers?.length == 0}
                    type="primary"
                    className="qu-soft"
                    size="small"
                    onClick={(e: any) => {
                      setShowServiceSection(true);
                      setShowPublisherSection(false);
                    }}
                  >
                    {t("Show all services from selected publishers")}
                  </QButton>
                </Col>
              </Row>
            </>
          )}

          {!showPublisherSection &&
            showServiceSection &&
            publishers?.length > 1 && (
              <Row>
                <Col md={24}>
                  <QBackLink
                    title={t("Back")}
                    onClick={() => {
                      setShowPublisherSection(true);
                      setShowServiceSection(false);
                    }}
                  />
                </Col>
              </Row>
            )}

          {showServiceSection && (
            <div className="mt-20">
              {getAvailableServicesFromSelectedPublishers().length == 0 && <div className="mb-40">{t("No services found")}</div>}
              {getAvailableServicesFromSelectedPublishers()?.map((s: any, index: number) => {
                return (
                  <div className="v3-services" key={index}>
                    <h6>
                      {s?.type === "SKU Uplifting" ? "Interactive Ad" : s?.type}
                    </h6>
                    <Row style={{ gap: "20px" }}>
                      {s?.services?.map((service: any, index2: number) => {
                        const isChecked = selectedServices.find(
                          (o: any) => o.name === service.serviceSubName
                        );
                        const image =
                          service.serviceImageUrl ??
                          BANNERS_BY_MEDIA_TYPE[service.serviceName]?.image;

                        const customImage =
                          BANNERS_BY_MEDIA_TYPE[
                            MEDIA_TYPES.CUSTOM_MEDIA_SERVICE
                          ]?.image;
                        return (
                          <Col className="brief-v3-services-container publisher-marketplace" key={index2}>
                            <QSelectItem
                              className={"mb-16 age-card"}
                              label={service.serviceSubName}
                              sublabel={generateSublabelString(service)}
                              checked={isChecked}
                              cardImage={
                                image ?? customImage ? (
                                  <img
                                    src={image ?? customImage}
                                    alt={service.serviceSubName}
                                  />
                                ) : (
                                  <div />
                                )
                              }
                              onChange={(e: any) =>
                                handleServiceChange(e, service)
                              }
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                );
              })}

              {getAvailableServicesFromSelectedPublishers().length > 0 && <Row className="mt-10">
                <Col md={24}>
                  <QButton
                    disabled={selectedPublishers?.length == 0}
                    type="primary"
                    className="qu-soft"
                    size="small"
                    onClick={(e: any) => {
                      setShowServiceSection(false);
                      setShowPublisherSection(true);
                      setShowModal(false);
                    }}
                  >
                    {t("Save")}
                  </QButton>
                </Col>
              </Row>}
            </div>
          )}
        </>
      </Modal>
    </div>
  );
};

export default React.memo(V3ServicesV2Component);
