import React, { useEffect, useState } from "react";
import { Tooltip, Checkbox } from "antd";
import {
  Col,
  Modal,
  QButton,
  QInput,
  qnotification,
  QSelect,
  QSelectItem,
  Row,
  Switch,
} from "quantum_components";
import { AgeGroupCard } from "src/modules/brief/containers/components/Brief.AgeGroupCard";
import { BANNERS_BY_MEDIA_TYPE, MEDIA_TYPES } from "src/constants/brief";
import ServiceV2Upload from "./ServiceV2Upload";
import lodash from "lodash";
import {
  createServicesV2,
  getServicesV2List,
  getServiceV2CustomMediaTypes,
  updateServicesV2,
} from "../servicesV2.actions";
import ServiceV2Tags from "./ServiceV2Tags";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomMediaServices } from "src/modules/partner/partner-Profile/profile.selectors";
import { fetchAllCustomMedia } from "src/modules/partner/partner-Profile/Profile.actions";
import { useTranslation } from "react-i18next";
import i18n from "src/i18n";
import {
  getAllNativeTemplates,
  getAllNativeTemplatesAsync,
} from "src/modules/nativeTemplates/nativeTemplates.actions";
import { getPublishersList } from "../../users/users.actions";
import { getPublishers } from "../../users/users.selectors";

const ServiceV2Create = ({
  setIsLoading,
  showTypesModal,
  setShowTypesModal,
  refreshParent,
  editService,
  setEditService,
}: any) => {
  const { t } = useTranslation(["translationServiceV2", "translation"]);
  const dispatch = useDispatch()
  const qrmIntegratedServices = [
    "Banner",
    "SKU Uplifting",
    "Search Bar Ads",
    "Cross Sell",
    "Product Recommendation",
    "Popup Window",
    "Video",
    "In-store",
  ];
  const [nativeTemplates, setNativeTemplates] = useState<any>([]);
  const [serviceTypes, setServiceTypes] = useState([]);

  const [integratedServices, setIntegratedServices] = useState<any>([]);
  const [normalServices, setNormalServices] = useState<any>([]);
  const publishers = useSelector(getPublishers)

  const getNativeTemplateList = async () => {
    const list = await getAllNativeTemplatesAsync();
    setNativeTemplates(
      lodash.get(list, "results", []).map((nt: any) => {
        return {
          label: nt.name,
          value: nt.id,
        };
      })
    );
  };

  useEffect(() => {
    getNativeTemplateList();
    dispatch(getPublishersList())
  }, []);

  useEffect(() => {
    const getCustomMediaTypes = async () => {
      const customMediaTypes = await getServiceV2CustomMediaTypes();
      let types: any = Object.keys(BANNERS_BY_MEDIA_TYPE).map((key) => {
        return {
          key: key,
          value: BANNERS_BY_MEDIA_TYPE[key].description,
          image: BANNERS_BY_MEDIA_TYPE[key].image,
        };
      });
      if (customMediaTypes.length > 0) {
        types = [
          ...types,
          ...customMediaTypes.map((cmt: any) => {
            return {
              key: cmt.name,
              value: cmt.description,
              image: BANNERS_BY_MEDIA_TYPE["Custom Media Service"].image,
            };
          }),
        ];
      }
      types = types.filter((t: any) => t.key !== "Custom Media Service");

      const integrated = [];
      const normal = [];
      for (const type of types) {
        if (qrmIntegratedServices.includes(type.key)) {
          integrated.push(type);
        } else {
          normal.push(type);
        }
      }

      setIntegratedServices(integrated);
      setNormalServices(normal);
      setServiceTypes(types);
    };
    getCustomMediaTypes();
  }, []);

  const [showEditModal, setShowEditModal] = useState(false);
  const [disabledHasServerIntegration, setDisabledHasServerIntegration] = useState(true);
  const [hasExistingServerIntegration, setHasExistingServerIntegration] = useState(false);
  const [service, setService] = useState<any>({
    type: "",
    name: "",
    subName: "",
    previewImage: null,
    description: "",
    isHide: false,
    avgImpressions: 0,
    width: 0,
    height: 0,
    executionType: null,
    pricePerExecution: 0,
    templateId: null,
    publisherId: null,
    pricePerMetric: { cpc: 0, cpm: 0 },
    hasServerIntegration: true,
  });

  useEffect(() => {
    if(!service?.publisherId && publishers.length){
      setService({ ...service, publisherId: publishers[0].id });
    }
  }, [publishers]);

  useEffect(() => {
    if (showEditModal) getServicesList()
  }, [showEditModal])

  const getServicesList = async () => {
    const data = await getServicesV2List();
    const alreadyServerIntegrationService = data?.results?.some(({ id, hasServerIntegration }: any) => (editService?.id === null || editService?.id === undefined || id !== editService?.id) && hasServerIntegration)
    setDisabledHasServerIntegration(alreadyServerIntegrationService)
    if (!editService?.id && alreadyServerIntegrationService) {
      setService({ ...service, hasServerIntegration: false })
    }
  }

  useEffect(() => {
    if (lodash.isEmpty(editService) || lodash.isUndefined(editService)) return;
    setHasExistingServerIntegration(editService.hasServerIntegration)
    setService(editService);
    setShowEditModal(true);
  }, [editService]);

  const handleNameSelect = (key: any, index: number) => {
    let type = ["Banner"].includes(key) ? "Display Ad" : "Promotion";
    const newService = { ...service, name: key, type };
    setService(newService);
  };

  const handleTypeNext = () => {
    if (!service.name) {
      qnotification({
        message: "Please select a service type first",
        type: "error",
      });
      return;
    }
    setShowTypesModal(false);
    setShowEditModal(true);
  };

  const pushRequiredFields = [
    "name",
    "subName",
    "description",
    "duration",
    "priceForDuration",
    "avgImpressions",
    "pricePerExecution",
    "previewImage"
  ];
  const allOthers = [
    "name",
    "subName",
    "description",
    "duration",
    "priceForDuration",
    "avgImpressions",
    "previewImage",
    "width",
    "height",
    "pricePerExecution",
  ];

  const validateDataAgainstMediaType = (data: any, requiredKeys: any) => {
    const errors: any = [];

    requiredKeys.forEach((key: string) => {
      if (
        [
          "name",
          "subName",
          "description",
          "executionType",
          "previewImage",
        ].includes(key) &&
        (lodash.isNull(data[key]) || lodash.isEmpty(data[key]))
      ) {
        if (service.hasServerIntegration && ["previewImage"].includes(key)) { }
        else if (!errors.includes(key)) errors.push(t(key));
      } else if (["avgImpressions"].includes(key)) {
        if (isNaN(Number(data[key])) || Number(data[key]) <= 0) {
          if (!errors.includes(key)) errors.push(t(key));
        }
      } else if (["width"].includes(key)) {
        if (isNaN(Number(data[key])) || Number(data[key]) < 0) {
          if (!errors.includes(key)) errors.push(t(key));
        } else {
          if (data[key] == 0 && data["height"] > 0)
            if (!errors.includes(key)) errors.push(t(key));
        }
      } else if (["height"].includes(key)) {
        if (isNaN(Number(data[key])) || Number(data[key]) < 0) {
          if (!errors.includes(key)) errors.push(t(key));
        } else {
          if (data[key] == 0 && data["width"] > 0)
            if (!errors.includes(key)) errors.push(t(key));
        }
      }
    });

    // check for executionType = Per Metrics and check if cpc and cpm are defined in pricePerMetric
    if (
      data["executionType"] === "Per Metric" &&
      (isNaN(Number(data["pricePerMetric"]["cpc"])) ||
        Number(data["pricePerMetric"]["cpc"]) <= 0)
    ) {
      if (!errors.includes("cpc")) errors.push(t("cpc"));
    }

    if (
      data["executionType"] === "Per Metric" &&
      (isNaN(Number(data["pricePerMetric"]["cpm"])) ||
        Number(data["pricePerMetric"]["cpm"]) <= 0)
    ) {
      if (!errors.includes("cpm")) errors.push(t("cpm"));
    }

    // check for pricePerExecution
    if (
      data["executionType"] !== "Per Metric" &&
      (isNaN(Number(data["pricePerExecution"])) ||
        Number(data["pricePerExecution"]) <= 0)
    ) {
      if (!errors.includes("Price")) errors.push(t("Price"));
    }

    // check if type is SKU Uplifting ... then check if templateId is not null and greater than 0
    if (
      [
        "SKU Uplifting",
        "Product Recommendation",
        "Search Bar Ads",
        "Cross Sell",
      ].includes(data["name"])
    ) {
      if (lodash.isNull(data["templateId"])) {
        if (!errors.includes("Native Template") && !service.hasServerIntegration)
          errors.push(t("Native Template"));
      }
    }

    if (!data["publisherId"] || lodash.isNull(data["publisherId"])) {
      if (!errors.includes("Publisher"))
        errors.push(t("Publisher"));
    }

    return errors;
  };

  const getRequiredServiceData = (service: any) => {
    const services = service;
    if (services.hasServerIntegration) {
      delete services.templateId
      delete services.width
      delete services.height
      delete services.previewImage
    }
    return services;
  };

  const validateData = (service: any) => {
    const errors = validateDataAgainstMediaType(
      service,
      ["Banner", "Popup Window"].includes(service.name) ? allOthers : pushRequiredFields
    );
    if (errors.length > 0) {
      qnotification({
        message: "Missing required field(s): " + errors.join(", "),
        type: "error",
      });
      return false;
    }
    return true;
  };

  const resetService = () => {
    setService({
      type: "",
      name: "",
      subName: "",
      previewImage: null,
      description: "",
      isHide: false,
      avgImpressions: 0,
      width: 0,
      height: 0,
      executionType: null,
      pricePerExecution: 0,
      templateId: null,
      pricePerMetric: { cpm: 0, cpc: 0 },
      hasServerIntegration: true,
    });
    setEditService({});
    setDisabledHasServerIntegration(true)
    setHasExistingServerIntegration(false)
  };

  const handleSave = async () => {
    if (!validateData(service)) return;
    const prunedData = getRequiredServiceData(service);

    setIsLoading(true);
    if (editService?.id) {
      await updateServicesV2(prunedData);
    } else {
      await createServicesV2(prunedData);
    }
    setIsLoading(false);

    setShowEditModal(false);
    await refreshParent();
    resetService();
  };

  const getExecutionOptionsPerService = (service: any) => {
    let execTypes = [
      "Per Hour",
      "Per Day",
      "Per Week",
      "Per Month",
      "Per Push",
      "Per Activity",
      "Per Metric",
    ];

    if (
      [
        "Banner",
        "Popup Window",
        "SKU Uplifting",
        "Cross Sell",
        "Product Recommendation",
        "Search Bar Ads",
      ].includes(service?.name)
    ) {
      execTypes = execTypes.filter(
        (k) => !["Per Push", "Per Activity"].includes(k)
      );
    }

    return execTypes.map((k) => {
      return { label: t(k), value: k };
    });
  };

  const renderFirstStep = () => {
    return (
      <div>
        <Modal
          wrapClassName={`services-v2 ${i18n.dir() === "rtl" ? "html-rtl" : ""
            }`}
          title={<h3>{t("Select Service Type")}</h3>}
          visible={showTypesModal}
          width={`${document.body.clientWidth} !important`}
          onCancel={() => {
            // resetService();
            setShowTypesModal(false);
          }}
          footer={false}
        >
          <div className="flex-container-row-flex-start m-30">
            <h6>{t("Integrated Services")}</h6>
            <Row gutter={24} className="full-width">
              {integratedServices.map((serviceType: any, index: number) => {
                const { key, value, image } = serviceType;
                const isChecked = key === service.name ? true : false;
                return (
                  <Col md={4} xs={24} key={key}>
                    <QSelectItem
                      className={`mb-16 age-card ${serviceType.key === "In-store" ? "in-store-icon" : ""}`}
                      label={t(`services.media-buy.types.${key}`, key, {
                        ns: "translation",
                      })}
                      sublabel={""}
                      checked={isChecked}
                      cardImage={<img src={image} alt={key} />}
                      onChange={(e: any) => handleNameSelect(key, index)}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
          <div className="flex-container-row-flex-start m-30">
            <h6>{t("Other Services")}</h6>
            <Row gutter={24} className="full-width">
              {normalServices.map((serviceType: any, index: number) => {
                const { key, value, image } = serviceType;
                const isChecked = key === service.name ? true : false;
                return (
                  <Col md={4} xs={24} key={key}>
                    <QSelectItem
                      className={"mb-16 age-card"}
                      label={t(`services.media-buy.types.${key}`, key, {
                        ns: "translation",
                      })}
                      sublabel={""}
                      checked={isChecked}
                      cardImage={<img src={image} alt={key} />}
                      onChange={(e: any) => handleNameSelect(key, index)}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
          <div>
            <Row
              align={"middle"}
              justify={"center"}
              className={"full-width mt-0 mb-15"}
            >
              <QButton
                type="primary"
                htmlType="button"
                onClick={handleTypeNext}
              >
                {t("Next")}
              </QButton>
            </Row>
          </div>
        </Modal>
      </div>
    );
  };

  const renderSecondStep = () => {
    return (
      <div>
        <Modal
          wrapClassName={`qu-modal qu-modal-details services-v2 ${i18n.dir() === "rtl" ? "html-rtl" : ""
            }`}
          title={
            <h3>
              {t(`services.media-buy.types.${service.name}`, service.name, {
                ns: "translation",
              })}{" "}
              {t("Details")}
            </h3>
          }
          visible={showEditModal}
          centered
          onCancel={() => {
            resetService();
            setShowEditModal(false);
          }}
          footer={false}
        >
          <div className="flex-container-row-space-between m-30">
            <Row gutter={24} style={{ width: "100%" }}>
              <Col sm={13} md={13}>
                {editService?.id && (
                  <Row className="mb-20">
                    <Col md={24} xs={24}>
                      <label className="label-required">
                        {t("Make It Visible For Advertisers?")}
                      </label>
                    </Col>
                    <Col md={24} xs={24}>
                      <Switch
                        checked={!service?.isHide}
                        onChange={() =>
                          setService({ ...service, isHide: !service.isHide })
                        }
                      />
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col md={24} xs={24}>
                    <Checkbox
                      disabled={disabledHasServerIntegration || hasExistingServerIntegration}
                      checked={service.hasServerIntegration}
                      onChange={() => setService({ ...service, hasServerIntegration: !service.hasServerIntegration })}
                      className={`qu-checkbox`}>
                      {t("Server Integration (Recommended)")}
                      <div style={{ marginTop: '8px', color: '#888' }}>
                        <small>
                          {t("you will receive row data from our Restful APIs without HTML elements")}
                        </small>
                      </div>
                    </Checkbox>
                  </Col>
                </Row>

                <Row className="mb-20">
                  <Col md={24} xs={24}>
                    <label className="label-required">
                      {t("Customized Name")}
                    </label>
                    <QInput
                      disabled={editService?.id}
                      size="large"
                      placeholder={t("Type here")}
                      className="full-width"
                      value={service?.subName}
                      onChange={(e: any) => {
                        setService({ ...service, subName: e.target.value });
                      }}
                    />
                    {service?.subName?.length >= 255 && (
                      <small className="text-red">
                        {t("Max 255 characters allowed")}
                      </small>
                    )}
                  </Col>
                </Row>
                <Row className="mb-20">
                  <Col md={24} xs={24}>
                    <label className="label-required">{t("Description")}</label>
                    <QInput
                      size="large"
                      placeholder={t("Type here")}
                      className="full-width"
                      value={service?.description}
                      onChange={(e: any) => {
                        setService({ ...service, description: e.target.value });
                      }}
                    />
                    {service?.description?.length >= 255 && (
                      <small className="text-red">
                        {t("Max 255 characters allowed")}
                      </small>
                    )}
                  </Col>
                </Row>
                {!service.hasServerIntegration &&
                  [
                    "SKU Uplifting",
                    "Search Bar Ads",
                    "Cross Sell",
                    "Product Recommendation",
                  ].includes(service?.name) && (
                    <Row className="mb-20">
                      <Col md={24} xs={24}>
                        <label className="label-required">
                          {t("Native Template")}
                        </label>
                        <QSelect
                          size="large"
                          className="full-width"
                          placeholder={t("Select type")}
                          value={service?.templateId}
                          options={nativeTemplates}
                          onChange={(selected: any) => {
                            setService({ ...service, templateId: selected });
                          }}
                        />
                      </Col>
                    </Row>
                  )}
                {!service.hasServerIntegration && <Row>
                  <Col md={11} xs={11}>
                    <label
                      className={
                        ["Banner", "Popup Window"].includes(service.name) ? "label-required" : ""
                      }
                    >
                      {t("Width")}
                    </label>
                    <QInput
                      disabled={editService?.id}
                      size="large"
                      placeholder={t("Type here")}
                      type={"number"}
                      className="full-width"
                      value={service?.width}
                      onChange={(e: any) =>
                        setService({ ...service, width: e.target.value })
                      }
                    />
                    {/* <small>
                      {t("Width can't be edited after service creation")}
                    </small> */}
                  </Col>
                  <Col md={2} xs={2}></Col>
                  <Col md={11} xs={11}>
                    <label
                      className={
                        ["Banner", "Popup Window"].includes(service.name) ? "label-required" : ""
                      }
                    >
                      {t("Height")}
                    </label>
                    <QInput
                      disabled={editService?.id}
                      size="large"
                      placeholder={t("Type here")}
                      type="number"
                      className="full-width"
                      value={service?.height}
                      onChange={(e: any) =>
                        setService({ ...service, height: e.target.value })
                      }
                    />
                    {/* <small>
                      {t("Height can't be edited after service creation")}
                    </small> */}
                  </Col>
                </Row>}
                <Row className="mb-20">
                  <Col md={24} xs={24}><small>{t("If both width and height are 0, then this service will accept display media of any size, otherwise a valid fixed size is required")}</small></Col>
                </Row>
                <Row className="mb-20" gutter={24}>
                  <Col md={8} xs={24}>
                    <label className="label-required">
                      {t("Execution Type")}
                    </label>
                    <QSelect
                      size="large"
                      className="full-width"
                      placeholder={t("Select type")}
                      value={service?.executionType}
                      // options={["Per Hour","Per Day", "Per Week", "Per Month","Per Push","Per Activity"].map((k) => {
                      //   return { label: t(k), value: k };
                      // })}
                      options={getExecutionOptionsPerService(service)}
                      onChange={(selected: any) => {
                        setService({ ...service, executionType: selected });
                      }}
                    />
                  </Col>
                  <Col md={8} xs={24}>
                    <label className="label-required">
                      {t("Avg. Impressions")}
                    </label>
                    <QInput
                      size="large"
                      placeholder={t("Type here")}
                      type="number"
                      className="full-width"
                      value={service?.avgImpressions}
                      onChange={(e: any) =>
                        setService({
                          ...service,
                          avgImpressions: e.target.value,
                        })
                      }
                    />
                  </Col>
                  {service?.executionType !== "Per Metric" && (
                    <Col md={8} xs={24}>
                      <label className="label-required">
                        {t("Price (SAR)")}
                      </label>
                      <QInput
                        size="large"
                        placeholder={t("Type here")}
                        type="number"
                        className="full-width"
                        value={service?.pricePerExecution ?? 0}
                        onChange={(e: any) =>
                          setService({
                            ...service,
                            pricePerExecution: e.target.value,
                          })
                        }
                      />
                    </Col>
                  )}
                  {service?.executionType === "Per Metric" && (
                    <Col md={8} xs={24} className="mb-10">
                      <label
                        className={
                          service.executionType === "Per Metric"
                            ? "label-required"
                            : ""
                        }
                      >
                        {t("CPM (SAR)")}{" "}
                        <Tooltip
                          data-html="true"
                          title={t(
                            "CPM (Cost Per Mille) is what advertisers spend for a thousand ad views."
                          )}
                        >
                          <span
                            style={{
                              marginLeft: "5px",
                              marginRight: "5px",
                              fontWeight: "bold",
                              fontSize: "15px",
                              color: "#aaaaaa",
                              cursor: "pointer",
                            }}
                          >
                            ⓘ
                          </span>
                        </Tooltip>
                      </label>
                      <QInput
                        size="large"
                        placeholder={t("Type here")}
                        type="number"
                        className="full-width"
                        value={service?.pricePerMetric?.cpm ?? 0}
                        onChange={(e: any) => {
                          const _service = { ...service };
                          const pricePerMetric: any = service?.pricePerMetric;
                          pricePerMetric["cpm"] = e.target.value;
                          _service.pricePerMetric = { ...pricePerMetric };
                          setService({
                            ..._service,
                          });
                        }}
                      />
                    </Col>
                  )}
                  {service?.executionType === "Per Metric" && (
                    <Col md={8} xs={24}>
                      <label
                        className={
                          service.executionType === "Per Metric"
                            ? "label-required"
                            : ""
                        }
                      >
                        {t("CPC (SAR)")}{" "}
                        <Tooltip
                          data-html="true"
                          title={t(
                            "CPC (Cost Per Click) is what advertisers pay when someone clicks their ad"
                          )}
                        >
                          <span
                            style={{
                              marginLeft: "5px",
                              marginRight: "5px",
                              fontWeight: "bold",
                              fontSize: "15px",
                              color: "#aaaaaa",
                              cursor: "pointer",
                            }}
                          >
                            ⓘ
                          </span>
                        </Tooltip>
                      </label>
                      <QInput
                        size="large"
                        placeholder={t("Type here")}
                        type="number"
                        className="full-width"
                        value={service?.pricePerMetric?.cpc ?? 0}
                        onChange={(e: any) => {
                          const _service = { ...service };
                          const pricePerMetric: any = service?.pricePerMetric;
                          pricePerMetric["cpc"] = e.target.value;
                          _service.pricePerMetric = { ...pricePerMetric };
                          setService({
                            ..._service,
                          });
                        }}
                      />
                    </Col>
                  )}
                </Row>
                {!service.hasServerIntegration && <Row className="mb-20">
                  <Col md={24} xs={24}>
                    <label
                      className={
                        "label-required"
                      }
                    >
                      {t("Preview Image")}
                    </label>
                    <ServiceV2Upload
                      setIsLoading={setIsLoading}
                      service={service}
                      setService={setService}
                    />
                  </Col>
                </Row>}
                <Row className="mb-20">
                  <Col md={24} xs={24}>
                    <label className="label">
                      {t("Publisher")}
                    </label>
                    <QSelect
                      showSearch
                      size="large"
                      className="full-width"
                      placeholder={t("Select publisher")}
                      value={service?.publisherId}
                      options={publishers?.map((nt: any, i: number) => {
                        return {
                          label: nt.companyName,
                          value: nt.id
                        }
                      })
                      }
                      filterOption={(input: string, option: any) =>
                        option.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                      }
                      onChange={(selected: any) => {
                        setService({ ...service, publisherId: selected });
                      }}
                    />
                  </Col>
                </Row>
                <Row className="pt-10 mb-20">
                  <Col md={24} xs={24}>
                    <QButton
                      disabled={
                        service?.description?.length > 255 ||
                        service?.subName?.length > 255
                      }
                      type="primary"
                      htmlType="button"
                      onClick={handleSave}
                    >
                      {t("Save")}
                    </QButton>
                  </Col>
                </Row>
              </Col>
              <Col sm={2} md={2}></Col>
              <Col sm={9} md={9}>
                <div className="flex-container-column-centered">
                  <h6 className="mb-10">{t("Preview")}</h6>
                  <div className="mb-10">
                    <img
                      src={
                        !service.previewImage!
                          ? "https://placehold.co/250x200?text=No%20Image%20Yet"
                          : service.previewImage!
                      }
                    />
                  </div>
                </div>
                {editService?.adspace?.tag && (
                  <div className="flex-container-column-centered">
                    <h6 className="mb-0">{t("Integration")}</h6>
                    <div className="mb-10">
                      <ServiceV2Tags selectedService={editService} />
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  };

  return (
    <>
      {renderFirstStep()}
      {renderSecondStep()}
    </>
  );
};

export default ServiceV2Create;
